import { useCallback, useState } from "react"
import { s } from "sanity-typed-schema-builder"
import { ctaType, MediaText1 } from "schemas"
import { customUrlType } from "schemas/objects/customUrl"
import { useEventListener, useIsomorphicLayoutEffect } from "usehooks-ts"
import util from "util"

import { MARKETING_FIELDS } from "./constants"
import { getCookieValue } from "./cookies"

type CTA = s.infer<typeof ctaType>
type URL = s.infer<typeof customUrlType>

const isCTA = (input: CTA | URL | string): input is CTA =>
  (input as CTA)._type === "customCta" || !!(input as CTA).url

const appendLocale = (input: string, locale: string) =>
  locale ? `/${locale}/${input}` : `/${input}`

export const getHref = (input: CTA | URL | string, locale?: string): string => {
  if (!input) return ""

  const url = isCTA(input) ? input.url : input

  if (typeof url === "string") return appendLocale(url, locale)

  return typeof url.external === "string"
    ? url.external
    : typeof url.internal === "string"
    ? appendLocale(url.internal, locale)
    : ""
  // (() => {
  //   throw new Error(`Could not get href ${JSON.stringify(input)}`)
  // })()
}

export const hasCTA = (input: CTA): string => {
  return input.text && getHref(input)
}

export const getParent = (input: any) => {
  if (!input) return ""

  return `${getParent(input.parent)}/${input.slug}`
}

export const serverLog = (x: any) =>
  console.log(
    util.inspect(x, {
      showHidden: false,
      depth: null,
      colors: true,
    })
  )

const paths = {
  "es-ES": {
    termsConditions: "/términos-y-condiciones",
    privacyPolicy: "/politica-de-privacidad",
  },
  "en-US": {
    termsConditions: "/términos-y-condiciones",
    privacyPolicy: "/politica-de-privacidad",
  },
}
export const getPath = <T extends keyof typeof paths>(
  page: keyof (typeof paths)[T],
  locale: T
) => {
  return paths[locale]?.[page] || ""
}

export function objectToFormData(obj, rootName?: any, ignoreList?: any) {
  const formData = new FormData()

  function appendFormData(data, root?: any) {
    if (!ignore(root)) {
      root = root || ""
      if (data instanceof File) {
        formData.append(root, data)
      } else if (Array.isArray(data)) {
        // if (data.length === 0) appendFormData("", `${root}[]`)

        for (let i = 0; i < data.length; i++) {
          appendFormData(data[i], `${root}[]`)
        }
      } else if (typeof data === "object" && data) {
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            if (root === "") {
              appendFormData(data[key], key)
            } else {
              appendFormData(data[key], `${root}.${key}`)
            }
          }
        }
      } else {
        if (data !== null && typeof data !== "undefined") {
          formData.append(root, data)
        }
      }
    }
  }

  function ignore(root) {
    return Array.isArray(ignoreList) && ignoreList.some(x => x === root)
  }

  appendFormData(obj, rootName)

  return formData
}

export function parseBackground(
  color1: string,
  color2: string,
  style: MediaText1["backgroundStyle"]
) {
  if (!color1 && !color2) return null

  if (color1 && !color2) return color1

  switch (style) {
    case "gradient":
      return `linear-gradient(180deg, ${color1 || "transparent"}, ${
        color2 || "transparent"
      })`
    case "halfHalf":
      return `linear-gradient(180deg, ${color1 || "transparent"} 50%, ${
        color2 || "transparent"
      } 50%)`
    default:
      return null
  }
}

export function useSizing(size?: string) {
  if (!size) return null
  return size.endsWith("px") ? size : `${size}px`
}

interface Size {
  width: number
  height: number
}

export function useElementSize<T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  Size
] {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [ref, setRef] = useState<T | null>(null)
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  })

  // Prevent too many rendering using useCallback
  const handleSize = useCallback(() => {
    const { height = 0, width = 0 } = ref?.getBoundingClientRect() || {}

    setSize({ width, height })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.offsetHeight, ref?.offsetWidth])

  useEventListener("resize", handleSize)

  useIsomorphicLayoutEffect(() => {
    handleSize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.offsetHeight, ref?.offsetWidth])

  return [setRef, size]
}

export const getUtmFields = () => {
  return MARKETING_FIELDS.map(field => ({
    field,
    value: getCookieValue(field),
  })).filter(x => x.value !== undefined)
}
