import { getHref } from "lib/helpers"
import { urlForImage } from "lib/sanity.image"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import { Settings } from "schemas"
import { styled, VStack } from "styled-system/jsx"

import s from "./MenuColumn.module.scss"

type Props = Extract<
  Settings["headerLinks"][0]["dropdown"]["columns"][0],
  { _type: "content" }
>

type ItemProps = Props["items"][0] & {
  type: Props["type"]
}

function Item(x: ItemProps) {
  const { locale } = useRouter()

  return (
    <styled.div
      css={{
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "auto 1fr",
        gap: 2,
        minWidth: "180px",
      }}>
      {x.icon ? (
        <div className={s.iconWrapper}>
          <Image alt="" src={`/icons/${x.icon}.svg`} width={16} height={16} />
        </div>
      ) : (
        <div className={s.icon} />
      )}
      <styled.a
        as={Link}
        href={getHref(x.url, locale)}
        className={s.itemLink}
        css={{
          color: "#000A3A",
          fontFamily: "heading",
          fontSize: "16px",
          lineHeight: "normal",
          fontWeight: 400,
        }}>
        {x.title}
      </styled.a>
    </styled.div>
  )
}

function MenuColumn({ title, body1, body2, image }: Props) {
  return (
    <styled.div
      css={{
        maxW: "265px",
        borderRight: "1px solid rgba(0, 0, 0, 0.1)",
        pr: 6,
        margin: "46px 0 56px",

        "&:last-child": {
          borderRight: 0,
        },
      }}>
      <styled.div
        css={{
          padding: "0 16px 16px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}>
        {title && (
          <styled.div
            css={{
              pb: 6,
              textTransform: "uppercase",
              color: "#000A3A",
              fontFamily: "heading",
              fontSize: "16px",
              fontWeight: 700,
            }}>
            {title}
          </styled.div>
        )}

        <VStack
          alignItems="start"
          css={{
            gap: 6,
            color: "#6332DF",
            fontFamily: "heading",
          }}>
          <styled.p
            css={{
              fontSize: "14px",
            }}>
            {body1}
          </styled.p>
          <styled.a
            as={Link}
            href={getHref(body2)}
            css={{
              fontSize: "16px",
              fontWeight: 600,

              _hover: {
                textDecoration: "underline",
              },
            }}>
            {body2.text}
          </styled.a>
        </VStack>
      </styled.div>

      {image && (
        <div className={s.imageWrapper}>
          <styled.img
            width={237}
            height={212}
            src={urlForImage(image).width(237).url()}
            className={s.image}
            css={{
              margin: 1,
            }}
            // alt={image.alt}
          />
        </div>
      )}
    </styled.div>
  )
}

export default MenuColumn
export type { Props as MenuDropdownProps }
