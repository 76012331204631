import { getHref } from "lib/helpers"
import { urlForImage } from "lib/sanity.image"
import Link from "next/link"
import { useRouter } from "next/router"
import { Settings } from "schemas"
import { styled } from "styled-system/jsx"

import s from "./MenuColumn.module.scss"

type Props = Extract<
  Settings["headerLinks"][0]["dropdown"]["columns"][0],
  { _type: "linkList" }
>

type ItemProps = Props["items"][0] & {
  type: Props["type"]
}

function Item(x: ItemProps) {
  const { locale } = useRouter()

  return (
    <styled.div
      css={{
        display: "grid",
        alignItems: "start",
        gridTemplateColumns: "auto 1fr",
        gap: 2,
        minWidth: "180px",
      }}>
      {x.icon ? (
        <div className={s.iconWrapper}>
          <styled.img
            alt=""
            src={urlForImage(x.icon).width(20).url()}
            width={20}
            height={20}
          />
        </div>
      ) : (
        <div className={s.icon} />
      )}
      <styled.a
        as={Link}
        href={getHref(x.url, locale)}
        className={s.itemLink}
        css={{
          color: "#000A3A",
          fontFamily: "heading",
          fontSize: "16px",
          lineHeight: "normal",
          fontWeight: 400,
        }}>
        {x.title}
      </styled.a>
    </styled.div>
  )
}

function MenuColumn({ color, customColor, items, title, type }: Props) {
  return (
    <styled.div
      css={{
        // Adjust this to the amount of columns being used
        flex: "0 0 calc((100% - var(--columnWidth)) / var(--columns))",
      }}
      className={s.wrapper}
      style={
        {
          "--color": customColor?.hex ? customColor.hex : color,
        } as React.CSSProperties
      }>
      {title && (
        <styled.div
          css={{
            pb: 6,
            textTransform: "uppercase",
            color: "#000A3A",
            fontFamily: "heading",
            fontSize: "16px",
            fontWeight: 700,
          }}>
          {title}
        </styled.div>
      )}
      <styled.div className={s[`itemsWrapper${type}`]}>
        {items?.map((x, i) => (
          <Item key={i} type={type} {...x} />
        ))}
      </styled.div>
    </styled.div>
  )
}

export default MenuColumn
export type { Props as MenuDropdownProps }
