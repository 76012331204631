import MenuColumn2 from "components/layout/MainHeader/MenuColumn2"
import { useRouter } from "next/router"
import { CSSProperties, RefObject } from "react"
import { Settings } from "schemas"

import MenuColumn from "../MenuColumn"
import s from "./MenuDropdown.module.scss"

type Props = {
  forwardRef: RefObject<HTMLDivElement>
  dropdown: Settings["headerLinks"][0]["dropdown"]
}

function MenuDropdown({ forwardRef, dropdown }: Props) {
  const { locale } = useRouter()

  return (
    <div
      className={s.wrapper}
      ref={forwardRef}
      style={
        {
          "--offset": `${dropdown.offset}px`,
          "--columns": `${dropdown.columns.length - 1}`,
          "--columnWidth": "265px",
        } as CSSProperties
      }>
      <div className={s.columnGrid}>
        {dropdown.columns.map((x, i) =>
          x._type === "linkList" ? (
            <MenuColumn key={i} {...x} />
          ) : (
            <MenuColumn2 key={i} {...x} />
          )
        )}
      </div>
    </div>
  )
}

export default MenuDropdown
export type { Props as MenuDropdownProps }
