import { getHref } from "lib/helpers"
import { urlForImage } from "lib/sanity.image"
import Link from "next/link"
import { useRouter } from "next/router"
import { Settings } from "schemas"
import { styled } from "styled-system/jsx"

import s from "./MenuColumn.module.scss"

type Props = Settings["headerLinks"][0]["dropdown"]["columns"][0]

type ItemProps = Props["items"][0] & {
  type: Props["type"]
}

function Item(x: ItemProps) {
  const { locale } = useRouter()

  return (
    <div className={s.item}>
      {x.icon ? (
        <div className={s.iconWrapper}>
          <styled.img
            alt=""
            src={urlForImage(x.icon).width(20).url()}
            width={20}
            height={20}
          />
        </div>
      ) : (
        <div className={s.icon} />
      )}
      <Link href={getHref(x.url, locale)} className={s.itemLink}>
        {x.title}
      </Link>
    </div>
  )
}

function MenuColumn({ color, customColor, items, title, type }: Props) {
  return (
    <div
      className={s.wrapper}
      style={
        {
          "--color": customColor?.hex ? customColor.hex : color,
        } as React.CSSProperties
      }>
      {title && <div className={s.title}>{title}</div>}
      <div className={s[`itemsWrapper${type}`]}>
        {items.map((x, i) => (
          <Item key={i} type={type} {...x} />
        ))}
      </div>
    </div>
  )
}

export default MenuColumn
export type { Props as MenuDropdownProps }
