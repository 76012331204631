import clsx from "clsx"
import { useRouter } from "next/router"
import { RefObject } from "react"
import { Settings } from "schemas"

import MenuColumn from "../BurgerMenuColumn"
import s from "./MenuDropdown.module.scss"

type Props = {
  forwardRef?: RefObject<HTMLDivElement>
  dropdown: Settings["headerLinks"][0]["dropdown"]
}

function MenuDropdown({ forwardRef, dropdown }: Props) {
  const { locale } = useRouter()

  return (
    <div className={s.wrapper} ref={forwardRef}>
      <div className={clsx(s.columnGrid)}>
        {dropdown.columns.map((x, i) =>
          x._type === "linkList" ? <MenuColumn key={i} {...x} /> : null
        )}
      </div>

      {/* {dropdown.bottomLink?.length > 0 && (
        <Link
          href={getHref(dropdown.bottomLink[0], locale)}
          className={s.bottomLink}>
          {dropdown.bottomLink[0].text}
        </Link>
      )} */}
    </div>
  )
}

export default MenuDropdown
export type { Props as MenuDropdownProps }
