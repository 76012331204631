import { ComponentProps } from "react"
import { styled, StyledComponent } from "styled-system/jsx"
import { token } from "styled-system/tokens"
import { SystemStyleObject } from "styled-system/types"

type Props = {
  children: React.ReactNode
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "h7" | "p" | "span" | "div"
  color?: string
  altColor?: string
  altCss?: SystemStyleObject
} & ComponentProps<StyledComponent<"p", {}>>

const Title = ({
  children,
  as = "p",
  color,
  css,
  altColor,
  altCss,
  ...rest
}: Props) => {
  const Tag = styled[as] as typeof styled.p

  return (
    <Tag
      {...rest}
      css={{
        color,
        "& em": {
          color: "var(--altColor)",
          ...altCss,
        },
        ...css,
      }}
      style={
        {
          "--altColor": token(`colors.${altColor}`, altColor),
        } as React.CSSProperties
      }>
      {children}
    </Tag>
  )
}

export type { Props as TitleProps }
export default Title
