import { assignInlineVars } from "@vanilla-extract/dynamic"
import { ReactNode } from "react"

import * as s from "./Heading.css"

type Level = 1 | 2 | 3 | 4 | 5 | 6 | "1" | "2" | "3" | "4" | "5" | "6"
type RGB = `rgb(${number}, ${number}, ${number})`
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`
type HEX = `#${string}`
type CSSVarFunction =
  | `var(--${string})`
  | `var(--${string}, ${string | number})`

type Color = RGB | RGBA | HEX | CSSVarFunction

type Props = {
  variant: Level
  component?: Level
  color?: Color
  className?: string
  children: ReactNode
}

function Heading({
  variant,
  component = variant,
  color,
  className,
  children,
}: Props) {
  const Tag = `h${component}` as const
  const variantClass = `h${variant}` as const

  return (
    <Tag
      className={className || s.variant[variantClass]}
      style={assignInlineVars({
        [s.color]: color,
      })}>
      {children}
    </Tag>
  )
}

export default Heading
export type { Props as HeadingProps }
