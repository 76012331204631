import { mapObject, __spreadValues, __objRest } from '../helpers.mjs';
import { css } from '../css/index.mjs';

const stackConfig = {
transform(props) {
  const _a = props, { align, justify, direction = "column", gap } = _a, rest = __objRest(_a, ["align", "justify", "direction", "gap"]);
  return __spreadValues({
    display: "flex",
    flexDirection: direction,
    alignItems: align,
    justifyContent: justify,
    gap
  }, rest);
}}

export const getStackStyle = (styles = {}) => stackConfig.transform(styles, { map: mapObject })

export const stack = (styles) => css(getStackStyle(styles))
stack.raw = getStackStyle