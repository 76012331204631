import Footer from "components/Footer"
import { GlobalContextProvider } from "components/layout/GlobalContext"
import GTM from "components/layout/GTM"
import MainHeader from "components/layout/MainHeader"
import Meta from "components/layout/Meta"
import PreviewBanner from "components/PreviewBanner"
import { Button } from "components/shared/panda/Button"
import { useSettings } from "context/SettingsContext"
import { getHref, useElementSize } from "lib/helpers"
import Script from "next/script"
import { Page } from "schemas"
import {
  Container,
  Grid,
  HStack,
  Stack,
  styled,
  VStack,
} from "styled-system/jsx"

import s from "./MainLayout.module.scss"

export default function MainLayout({
  preview,
  loading,
  page,
  children,
  minimal,
}: {
  preview?: boolean
  loading?: boolean
  page?: Page
  children: React.ReactNode
  minimal?: boolean
}) {
  const settings = useSettings()

  return (
    <GlobalContextProvider>
      <Meta settings={settings} page={page} />

      <div className={s.wrapper}>
        <PreviewBanner preview={preview} loading={loading} />
        {settings.topBannerEnabled && !page?.hideTopBanner && <GlobalBanner />}
        {!minimal && (
          <MainHeader
            simple={page?.simpleHeader}
            empty={page?.emptyHeader}
            white={page?.whiteBackground}
            isContactHeader={page?.contactHeader}
          />
        )}
        <styled.main
          // css={{ mt: "calc(120px + (var(--headerOffset, -25px) * -1))" }}>
          css={{
            mt: "calc(120px + (var(--headerOffset, 0px) * -1))",
            overflow: "hidden",
          }}>
          {children}
        </styled.main>
        {!minimal && <Footer simple={page?.simpleFooter} />}
      </div>

      <GTM />
      <Script
        src="//js-eu1.hs-scripts.com/26890207.js"
        strategy="afterInteractive"
      />
      <div id="CollectedForms-26890207" />
    </GlobalContextProvider>
  )
}

const GlobalBanner = () => {
  const [ref, { height }] = useElementSize()
  const settings = useSettings()

  return (
    <>
      <styled.div
        ref={ref}
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          color: "white",
          transform: "translateY(-100%)",
          mb: "-24px",
          bgImage:
            "url(/textures/topBanner-bg.svg), linear-gradient(91deg, #1800D9 0%, #3726C1 100%)",
          backgroundPosition: "center 45%",
          backgroundRepeat: "no-repeat",
          overflow: "hidden",
        }}>
        <Container
          css={{
            py: "40px",
            lgDown: {
              pt: 4,
              pb: 6,
            },
          }}>
          <Grid gap={10} gridTemplateColumns={{ lg: "auto 1fr" }}>
            <styled.div css={{ width: "230px", hideBelow: "lg" }} />
            <Stack
              direction={{ base: "column", lg: "row" }}
              gap={4}
              align={{ base: "start", lg: "center" }}
              justify="space-between">
              <VStack gap={2} alignItems="start">
                <styled.h3
                  css={{
                    fontSize: { base: "14px", md: "26px" },
                    fontFamily: "heading",
                    fontWeight: 600,
                    lineHeight: { base: "normal", md: "32px" },
                  }}>
                  {settings.topBannerTitle}
                </styled.h3>
                <styled.p
                  css={{
                    fontSize: { base: "12px", md: "14px" },
                    color: "#D6DDFF",
                    fontWeight: 500,
                    lineHeight: { base: "normal", md: "133%" },
                  }}>
                  {settings.topBannerSubtitle}
                </styled.p>
              </VStack>

              <HStack justifyContent="space-between">
                {settings.topBannerCta && (
                  <Button
                    href={getHref(settings.topBannerCta)}
                    variant="filled"
                    fillColor="tertiary"
                    size="medium"
                    responsive>
                    {settings.topBannerCta.text}
                  </Button>
                )}
                <styled.img
                  src="/top-banner.png"
                  css={{
                    position: "absolute",
                    bottom: "-1px",
                    lg: {
                      left: "24px",
                    },
                    lgDown: {
                      width: "120px",
                      right: "24px",
                    },
                  }}
                />
              </HStack>
            </Stack>
          </Grid>
        </Container>
      </styled.div>
      <style>
        {`
          html {
            margin-top: ${`${height || 190}px`};
            --headerOffset: 0px;
          }
        `}
      </style>
    </>
  )
}
