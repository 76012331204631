import { mapObject, __spreadValues, __objRest } from '../helpers.mjs';
import { css } from '../css/index.mjs';

const hstackConfig = {
transform(props) {
  const _a = props, { justify, gap = "10px" } = _a, rest = __objRest(_a, ["justify", "gap"]);
  return __spreadValues({
    display: "flex",
    alignItems: "center",
    justifyContent: justify,
    gap,
    flexDirection: "row"
  }, rest);
}}

export const getHstackStyle = (styles = {}) => hstackConfig.transform(styles, { map: mapObject })

export const hstack = (styles) => css(getHstackStyle(styles))
hstack.raw = getHstackStyle