import { Button } from "components/shared/panda/Button"
import Title from "components/shared/Title"
import { parseBackground } from "lib/helpers"
import { ThankYou } from "schemas"
import { Container, styled, VStack } from "styled-system/jsx"

type Props = ThankYou

function Columns({
  title,
  subtitle,
  body,
  ctaLabel,
  ctaPhone,

  backgroundColor1,
  backgroundColor2,
  backgroundStyle,
}: Props) {
  return (
    <styled.div
      className="thankYou"
      style={
        {
          background: parseBackground(
            backgroundColor1,
            backgroundColor2,
            backgroundStyle
          ),
        } as React.CSSProperties
      }
      css={{
        py: "140px",
        textAlign: "center",
      }}>
      <Container>
        <VStack gap={8}>
          <Title
            as="h3"
            css={{
              fontFamily: "heading",
              fontSize: "56px",
              fontWeight: 600,
              lineHeight: "136%",
            }}>
            {title}
          </Title>
          <Title
            as="h4"
            css={{
              color: "#373737",
              fontSize: "32px",
              fontWeight: 600,
              lineHeight: "133%",
              maxW: "922px",
            }}>
            {subtitle}
          </Title>
          <styled.p
            css={{
              color: "#373737",
              fontSize: "24px",
              lineHeight: "133%",
              maxW: "922px",
            }}>
            {body}
          </styled.p>
          <Button
            variant="filled"
            fillColor="secondary"
            color="white"
            href={`tel:${ctaPhone}`}>
            {ctaLabel} <Arrow />
          </Button>
        </VStack>
      </Container>
    </styled.div>
  )
}

export const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none">
    <g clipPath="url(#clip0_575_2879)">
      <path
        d="M27.8923 3.94028C27.8608 3.90876 27.8608 3.90876 27.8293 3.87724L24.425 0.914196C23.9521 0.472892 23.3217 0.252239 22.6282 0.315283C22.0293 0.378326 21.4304 0.6305 20.9891 1.0718C20.9576 1.10333 20.926 1.13485 20.8945 1.16637L17.5532 5.39028C16.6706 6.33594 16.7021 7.78593 17.6163 8.70007C17.6793 8.76311 17.7423 8.82615 17.8054 8.85767L19.5076 9.99246C19.3815 10.5914 18.4989 12.2935 15.3782 15.4142C12.2891 18.5033 10.5554 19.3859 9.988 19.512L8.85322 17.8098C8.8217 17.7468 8.75865 17.6838 8.69561 17.6207C7.78148 16.7066 6.29996 16.7066 5.38583 17.5577L1.19344 20.899C1.16191 20.9305 1.13039 20.962 1.09887 20.9935C0.153219 21.9392 0.0901754 23.5153 0.941262 24.4294L3.90431 27.8022C3.93583 27.8338 3.93583 27.8338 3.96735 27.8653C4.53474 28.4327 5.41735 28.6533 6.39452 28.6533C8.12822 28.6533 10.1141 27.9598 11.1543 27.5501C13.1717 26.762 17.1119 24.8392 21.0206 20.9305C23.9206 18.0305 26.2532 14.5316 27.6402 11.0642C28.2076 9.48811 29.5315 5.57941 27.8923 3.94028ZM19.1923 19.1968C13.3608 25.0283 6.80431 26.6359 5.70104 26.1001L2.83257 22.7903L6.93039 19.5435L8.03365 21.2142C8.06517 21.2457 8.06518 21.2772 8.0967 21.3088C8.47496 21.7816 9.01083 22.0338 9.64126 22.0653C10.2402 22.0968 12.1 22.1914 17.1119 17.1794C18.3413 15.9501 22.1239 12.1675 21.9978 9.70876C21.9663 9.07833 21.6826 8.54246 21.2413 8.1642C21.2097 8.13267 21.1782 8.10115 21.1467 8.10115L19.5391 6.96637L22.7858 2.83702L26.0641 5.7055C26.6315 6.80876 25.0239 13.3653 19.1923 19.1968Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_575_2879">
        <rect width="29" height="29" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Columns
export type { Props as ThankYouProps }
