import FingerprintJS from "@fingerprintjs/fingerprintjs"
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"

type GlobalState = {
  fpHash?: string
}

type GlobalContextProps = {
  children: ReactNode
}

export const GlobalContext = createContext<GlobalState>({})

export const useGlobalContext = () => useContext(GlobalContext)

export const GlobalContextProvider = ({ children }: GlobalContextProps) => {
  const [fpHash, setFpHash] = useState("")

  useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJS.load()

      const { visitorId } = await fp.get()

      setFpHash(visitorId)
    }

    setFp()
  }, [])

  const value = useMemo(
    () => ({
      fpHash,
    }),
    [fpHash]
  )

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}
