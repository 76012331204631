import Link from "next/link"

type Props = {}

let id = 0

function Logo({}: Props) {
  const innerId = id++

  return (
    <Link href="/">
      {/* <img alt="Vixiees logo" src="/main-logo.svg" /> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="209"
        height="42"
        viewBox="0 0 209 42"
        fill="none">
        <path
          d="M58.9531 11.1372H65.4929L71.7242 30.0103L77.9096 11.1414H84.4494L75.5588 35.283H67.8437L58.9531 11.1372Z"
          fill="#1800D9"
        />
        <path
          d="M94.8272 5.00178C94.8192 5.61146 94.6617 6.20984 94.3685 6.74445C94.0753 7.27906 93.6554 7.73355 93.1456 8.06806C92.6358 8.40257 92.0517 8.60687 91.4446 8.66304C90.8375 8.71922 90.2258 8.62555 89.6633 8.39026C89.1008 8.15496 88.6046 7.78523 88.2183 7.3135C87.832 6.84176 87.5674 6.28244 87.4476 5.68459C87.3278 5.08674 87.3566 4.46863 87.5314 3.88449C87.7061 3.30035 88.0216 2.76802 88.45 2.3342C88.9731 1.80755 89.6413 1.44889 90.3693 1.3041C91.0972 1.1593 91.8518 1.23496 92.5366 1.52138C93.2213 1.80781 93.805 2.29198 94.2131 2.91197C94.6211 3.53197 94.835 4.25959 94.8272 5.00178ZM88.0332 35.2788V11.1372H94.177V35.2788H88.0332Z"
          fill="#1800D9"
        />
        <path
          d="M119.925 11.1372L112.08 22.9454L119.879 35.2788H112.993L108.375 28.0013L103.932 35.2788H97.7419L105.536 23.5123L97.7419 11.1372H104.628L109.246 18.4564L113.693 11.1372H119.925Z"
          fill="#1800D9"
        />
        <path
          d="M130.247 5.00176C130.264 5.50132 130.18 5.99912 130 6.46556C129.821 6.93199 129.549 7.35752 129.202 7.71682C128.854 8.07611 128.438 8.36183 127.978 8.55696C127.518 8.75209 127.023 8.85265 126.523 8.85265C126.023 8.85265 125.529 8.75209 125.068 8.55696C124.608 8.36183 124.192 8.07611 123.845 7.71682C123.497 7.35752 123.225 6.93199 123.046 6.46556C122.866 5.99912 122.782 5.50132 122.799 5.00176C122.782 4.50221 122.866 4.0044 123.046 3.53797C123.225 3.07153 123.497 2.64601 123.845 2.28671C124.192 1.92742 124.608 1.6417 125.068 1.44657C125.529 1.25144 126.023 1.15088 126.523 1.15088C127.023 1.15088 127.518 1.25144 127.978 1.44657C128.438 1.6417 128.854 1.92742 129.202 2.28671C129.549 2.64601 129.821 3.07153 130 3.53797C130.18 4.0044 130.264 4.50221 130.247 5.00176ZM123.458 35.2788V11.1372H129.605V35.2788H123.458Z"
          fill="#1800D9"
        />
        <path
          d="M146.333 35.629C142.821 35.629 139.959 34.4883 137.747 32.207C135.535 29.9256 134.432 26.9121 134.438 23.1664C134.438 19.4151 135.549 16.4154 137.772 14.1674C139.995 11.9194 142.867 10.7927 146.387 10.7871C149.919 10.7871 152.817 11.8847 155.082 14.0799C157.347 16.2751 158.478 19.2011 158.475 22.8579C158.479 23.6463 158.421 24.4338 158.3 25.2129H140.711C140.796 26.6505 141.419 28.0033 142.457 29.0017C143.508 29.976 144.901 30.4946 146.333 30.4438C148.453 30.4438 150.022 29.5713 151.039 27.8263H157.662C156.981 30.1023 155.554 32.0829 153.611 33.449C151.607 34.9023 149.181 35.629 146.333 35.629ZM150.364 17.4144C149.277 16.4566 147.869 15.9432 146.421 15.9764C144.997 15.9301 143.611 16.4467 142.565 17.4144C141.505 18.427 140.846 19.7889 140.711 21.249H152.127C152.114 20.5226 151.951 19.8067 151.648 19.1466C151.344 18.4866 150.907 17.8967 150.364 17.4144Z"
          fill="#1800D9"
        />
        <path
          d="M173.521 35.629C170.008 35.629 167.146 34.4883 164.934 32.207C162.723 29.9256 161.619 26.9121 161.625 23.1664C161.625 19.4151 162.737 16.4154 164.959 14.1674C167.182 11.9194 170.058 10.7927 173.587 10.7871C177.119 10.7871 180.017 11.8847 182.282 14.0799C184.547 16.2751 185.679 19.2011 185.679 22.8579C185.683 23.6463 185.625 24.4338 185.504 25.2129H167.898C167.983 26.6505 168.607 28.0033 169.644 29.0017C170.695 29.976 172.089 30.4946 173.521 30.4438C175.641 30.4438 177.21 29.5713 178.227 27.8263H184.85C184.169 30.1023 182.742 32.0829 180.798 33.449C178.795 34.9023 176.369 35.629 173.521 35.629ZM177.551 17.4144C176.464 16.4566 175.057 15.9432 173.608 15.9764C172.184 15.9301 170.799 16.4467 169.753 17.4144C168.692 18.427 168.033 19.7889 167.898 21.249H179.31C179.298 20.5229 179.136 19.8072 178.833 19.1472C178.53 18.4871 178.094 17.8971 177.551 17.4144Z"
          fill="#1800D9"
        />
        <path
          d="M189.167 18.198C189.167 16.1056 190.053 14.3481 191.826 12.9253C193.599 11.5026 195.958 10.7899 198.903 10.7871C201.835 10.7871 204.187 11.4929 205.96 12.9045C206.829 13.5878 207.534 14.4566 208.025 15.4474C208.515 16.4382 208.778 17.5261 208.794 18.6315H202.505C202.33 16.5974 201.079 15.5804 198.753 15.5804C197.802 15.5225 196.856 15.7676 196.052 16.2807C195.745 16.4948 195.497 16.7824 195.329 17.1174C195.162 17.4523 195.081 17.8238 195.094 18.198C195.094 19.0122 195.573 19.6513 196.532 20.1153C197.642 20.6258 198.813 20.9914 200.016 21.2032C201.383 21.4644 202.742 21.7909 204.093 22.1827C205.412 22.5572 206.608 23.2766 207.556 24.2667C208.046 24.8046 208.425 25.4337 208.672 26.1181C208.919 26.8025 209.028 27.5289 208.994 28.2556C209.009 29.3013 208.764 30.3343 208.281 31.2619C207.798 32.1895 207.092 32.9826 206.227 33.5699C204.384 34.9371 202.032 35.6206 199.17 35.6206C196.308 35.6206 193.961 34.9454 192.13 33.5949C190.302 32.2417 189.3 30.2966 189.125 27.7596H195.377C195.624 29.8159 196.925 30.844 199.278 30.844C200.251 30.8904 201.212 30.623 202.021 30.0812C202.351 29.8611 202.621 29.5612 202.804 29.2093C202.988 28.8574 203.08 28.4649 203.071 28.068C203.071 27.2344 202.592 26.58 201.633 26.1049C200.526 25.5816 199.352 25.2128 198.145 25.0087C196.778 24.7475 195.419 24.4279 194.068 24.05C192.755 23.6924 191.56 22.9935 190.605 22.0243C190.119 21.5165 189.742 20.9158 189.494 20.2582C189.247 19.6006 189.136 18.8998 189.167 18.198Z"
          fill="#1800D9"
        />
        <path
          d="M42.3108 1.8706e-06C43.5602 -0.000960817 44.7816 0.369675 45.8197 1.06477C46.8578 1.75987 47.6657 2.74802 48.1407 3.90355C48.6157 5.05907 48.7363 6.32976 48.4871 7.55401C48.238 8.77825 47.6303 9.90074 46.7415 10.7787L38.7054 18.7314C38.4081 19.0256 38.1721 19.3758 38.0111 19.7618C37.85 20.1478 37.7671 20.5619 37.7671 20.9801C37.7671 21.3983 37.85 21.8124 38.0111 22.1984C38.1721 22.5844 38.4081 22.9346 38.7054 23.2288L46.7415 31.1815C47.6289 32.0597 48.2353 33.1818 48.4836 34.4054C48.7319 35.6289 48.611 36.8986 48.1362 38.0533C47.6614 39.208 46.8541 40.1955 45.8169 40.8903C44.7796 41.5852 43.5593 41.9562 42.3108 41.9561C40.6516 41.9562 39.0594 41.3017 37.8801 40.1346L23.0459 25.4546C22.4546 24.8686 21.9852 24.1713 21.6649 23.403C21.3446 22.6347 21.1797 21.8105 21.1797 20.978C21.1797 20.1456 21.3446 19.3214 21.6649 18.5531C21.9852 17.7847 22.4546 17.0874 23.0459 16.5015L37.8801 1.82146C39.0594 0.654392 40.6516 -0.000165476 42.3108 1.8706e-06Z"
          fill={`url(#logo-b-${innerId})`}
        />
        <path
          d="M16.4526 7.58185e-07C15.2042 0.00104792 13.9842 0.372868 12.9474 1.0683C11.9106 1.76373 11.1036 2.75143 10.629 3.90613C10.1543 5.06082 10.0333 6.33048 10.2813 7.55404C10.5293 8.7776 11.1351 9.89994 12.0219 10.7787L19.983 18.6564C20.2899 18.9606 20.5334 19.3226 20.6997 19.7214C20.8659 20.1202 20.9515 20.548 20.9515 20.9801C20.9515 21.4122 20.8659 21.84 20.6997 22.2388C20.5334 22.6377 20.2899 22.9996 19.983 23.3038L12.0219 31.1815C8.02057 35.137 10.8257 41.9561 16.4526 41.9561C18.1131 41.9569 19.7069 41.3023 20.8875 40.1346L35.7176 25.4546C36.3099 24.8692 36.7801 24.1721 37.1011 23.4037C37.422 22.6353 37.5873 21.8108 37.5873 20.978C37.5873 20.1453 37.422 19.3208 37.1011 18.5524C36.7801 17.784 36.3099 17.0869 35.7176 16.5015L20.8875 1.82146C19.7069 0.65376 18.1131 -0.000813188 16.4526 7.58185e-07Z"
          fill="#1800D9"
        />
        <path
          d="M6.20196 26.6798C9.35106 26.6798 11.9039 24.1269 11.9039 20.9778C11.9039 17.8287 9.35106 15.2759 6.20196 15.2759C3.05285 15.2759 0.5 17.8287 0.5 20.9778C0.5 24.1269 3.05285 26.6798 6.20196 26.6798Z"
          fill="#1800D9"
        />
        <defs>
          <linearGradient
            id={`logo-b-${innerId}`}
            x1="21.1786"
            y1="20.978"
            x2="48.6255"
            y2="20.978"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#9FAFFF" />
            <stop offset="0.05" stopColor="#9EAAFF" />
            <stop offset="0.47" stopColor="#9482FC" />
            <stop offset="0.8" stopColor="#8E69FB" />
            <stop offset="1" stopColor="#8C60FA" />
          </linearGradient>
        </defs>
      </svg>
    </Link>
  )
}

export default Logo
export type { Props as LogoProps }
