/* eslint-disable react/jsx-key */
import closeSvg from "@material-design-icons/svg/filled/close.svg"
import menuSvg from "@material-design-icons/svg/filled/menu.svg"
import BurgerMenuDropdown from "components/layout/MainHeader/BurgerMenuDropdown"
import Accordion from "components/shared/Accordion"
import Logo from "components/shared/Logo"
import LogoBlue from "components/shared/LogoBlue"
import { Button } from "components/shared/panda/Button"
import { Arrow } from "components/ThankYou"
import { useSettings } from "context/SettingsContext"
import { getHref } from "lib/helpers"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { ScrollEventHandler } from "sanity"
import { cx } from "styled-system/css"
import { Container, styled } from "styled-system/jsx"
import { useLockedBody, useMediaQuery } from "usehooks-ts"

import s from "./MainHeader.module.scss"
import MenuSection from "./MenuSection"

const useFloating = () => {
  const [floating, setFloating] = useState(false)

  useEffect(() => {
    if (typeof window === "undefined") return null

    const triggerHeight = 144 + 35

    const onScroll: ScrollEventHandler = () => {
      const scrollTop = window.scrollY

      if (!floating && scrollTop > triggerHeight) {
        setFloating(true)
      } else if (floating && scrollTop <= triggerHeight) {
        setFloating(false)
      }
    }

    onScroll(null)

    window.addEventListener("scroll", onScroll)
    window.addEventListener("resize", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
      window.removeEventListener("resize", onScroll)
    }
  }, [floating])

  return floating
}

export default function MainHeader({ simple, white, isContactHeader, empty }) {
  const [burgerOpen, setBurgerOpen] = useState(false)
  const matches = useMediaQuery("(min-width: 1280px)")
  const { asPath } = useRouter()

  useEffect(() => {
    if (matches) setBurgerOpen(false)
  }, [matches])

  useEffect(() => {
    setBurgerOpen(false)
  }, [asPath])

  useLockedBody(burgerOpen, "__next")

  const { locale } = useRouter()
  const { headerLinks, headerTopLink, headerActions } = useSettings()
  const floating = useFloating()

  return (
    <>
      <styled.header
        data-floating={floating}
        className={cx(white && "light")}
        css={{
          position: "absolute",
          top: "0",
          zIndex: "50",
          width: "100%",
          // bgColor: "white",
          bgColor: "other.1",
          color: "white",
          minH: "120px",
          // TODO
          // transform: { xlDown: "translateY(var(--headerOffset, -25px))" },
          transition: "background-color .3s",

          _floating: {
            position: "fixed",
            bgColor: "white",
            color: "primary.1",
            transform: "translateY(-35px)",
            // transition: "transform .3s, background-color .3s",
          },

          _light: {
            bgColor: "white",
            color: "primary.1",
          },
        }}>
        <Container css={{ py: 2 }}>
          <div className={s.topBar}>
            {/* <Link
              className={s.todo}
              href={{
                pathname: "/[[...slug]]",
                query: { slug: ["home"] },
              }}
              locale={locale === "es-ES" ? "en-US" : "es-ES"}
              as="/">
              {locale === "es-ES" ? "English (GB)" : "Español (ES)"}
            </Link> */}
            {headerTopLink?.[0] && (
              <styled.a
                as={Link}
                href={getHref(headerTopLink[0])}
                className={s.todo}
                disabled={isContactHeader}
                css={{
                  _disabled: {
                    visibility: "hidden",
                  },
                  visibility: {
                    base: empty ? "hidden" : undefined,
                    xlDown: "hidden",
                  },
                }}>
                {headerTopLink[0].text}
              </styled.a>
            )}
          </div>
          <div className={s.content}>
            {floating || white ? <LogoBlue /> : <Logo />}
            <styled.div
              css={{
                display: "grid",
                gridAutoFlow: "column",
                alignItems: "center",
                gap: 4,
                paddingLeft: "2rem",
                textAlign: "left",
              }}>
              {!(simple || empty) &&
                !isContactHeader &&
                headerLinks?.map((link, i) => (
                  <MenuSection key={i} {...link} />
                ))}
              {!(isContactHeader || empty) &&
                headerActions?.map((link, i) => (
                  <Button
                    css={{ hideBelow: "xl" }}
                    key={link._key}
                    as={Link}
                    href={getHref(link)}
                    size="medium"
                    {...(i % 2 === 0
                      ? {
                          variant: "outline",
                          outlineColor: "secondary",
                        }
                      : {
                          variant: "filled",
                          fillColor: "secondary",
                          color: "white",
                        })}>
                    {link.text}
                  </Button>
                ))}
              {isContactHeader && (
                <>
                  {headerTopLink?.[0] && (
                    <styled.a
                      as={Link}
                      href={getHref(headerTopLink[0])}
                      className={s.todo}
                      css={{
                        visibility: {
                          xlDown: "none",
                        },
                      }}>
                      {headerTopLink[0].text}
                    </styled.a>
                  )}
                  <Button
                    as={Link}
                    href="tel:+34694235365"
                    size="medium"
                    variant="filled"
                    fillColor="secondary"
                    color="white"
                    css={{
                      display: "flex",
                      flexDir: "row",
                      gap: 4,
                      hideBelow: "xl",
                      fontSize: "16px",
                    }}>
                    +34 694 23 53 65
                    <Arrow />
                  </Button>
                </>
              )}
              {!empty && (
                <button
                  className={s.burgerButton}
                  onClick={() => setBurgerOpen(x => !x)}>
                  <Image
                    alt=""
                    width={32}
                    height={32}
                    src={burgerOpen ? closeSvg : menuSvg}
                    style={{
                      filter:
                        "invert(52%) sepia(62%) saturate(536%) hue-rotate(170deg) brightness(84%) contrast(88%)",
                    }}
                  />
                </button>
              )}
            </styled.div>
          </div>
        </Container>
      </styled.header>
      {burgerOpen && <BurgerMenu onClose={() => setBurgerOpen(false)} />}
    </>
  )
}

function BurgerMenu({ onClose }) {
  const { locale } = useRouter()
  const { headerLinks, headerTopLink, headerActions } = useSettings()
  const [open, setOpen] = useState(-1)

  return (
    <div>
      <div className={s.burgerWrapper}>
        <styled.div
          css={{
            display: "flex",
            justifyContent: "space-between",
            mb: 20,
          }}>
          <Logo />
          <styled.div
            css={{
              display: "grid",
              gridAutoFlow: "column",
              alignItems: "center",
              gap: 4,
              paddingLeft: "2rem",
              textAlign: "left",
            }}>
            <button className={s.burgerButton} onClick={onClose}>
              <Image
                alt=""
                width={32}
                height={32}
                src={closeSvg}
                style={{
                  filter:
                    "invert(52%) sepia(62%) saturate(536%) hue-rotate(170deg) brightness(84%) contrast(88%)",
                }}
              />
            </button>
          </styled.div>
        </styled.div>
        <div className={s.burgerContent}>
          <styled.div
            css={{
              display: "flex",
              justifyContent: "space-between",
              color: "#9FAFFF",
              fontFamily: "heading",
              fontSize: "16px",
              fontWeight: 500,
            }}>
            {/* <Link
              // className={s.todo}
              href={{
                pathname: "/[[...slug]]",
                query: { slug: ["home"] },
              }}
              locale={locale === "es-ES" ? "en-US" : "es-ES"}
              as="/">
              {locale === "es-ES" ? "English (GB)" : "Español (ES)"}
            </Link> */}
            {headerTopLink?.[0] && (
              <Link
                href={getHref(headerTopLink[0])}
                // className={s.todo}
              >
                {headerTopLink[0].text}
              </Link>
            )}
          </styled.div>
          <div className={s.burgerList}>
            {headerLinks?.map((link, i) =>
              link.hasDropdown ? (
                <Accordion
                  key={link._key}
                  heading={link.title}
                  isOpen={open === i}
                  onToggle={() => (open ? setOpen(i) : setOpen(-1))}>
                  {link.hasDropdown && (
                    <BurgerMenuDropdown
                      key={link._key}
                      dropdown={link.dropdown}
                    />
                  )}
                </Accordion>
              ) : (
                <styled.a
                  key={link._key}
                  href={getHref(link.url, locale)}
                  css={{
                    color: "#DEDEDE",
                    fontFamily: "heading",
                    fontSize: "24px",
                    fontWeight: 500,
                  }}>
                  {link.title}
                </styled.a>
              )
            )}
          </div>
          <div className={s.burgerActions}>
            {headerActions?.map((link, i) => (
              <Button
                key={link._key}
                as={Link}
                href={getHref(link)}
                size="medium"
                {...(i % 2 === 0
                  ? {
                      variant: "outline",
                      outlineColor: "secondary",
                    }
                  : {
                      variant: "filled",
                      fillColor: "secondary",
                    })}>
                {link.text}
              </Button>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
