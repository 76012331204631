import {
  PortableText as BasePortableText,
  PortableTextProps,
} from "@portabletext/react"
import clsx from "clsx"
import Heading from "components/Heading"
import Typography from "components/Typography"

import s from "./PortableText.module.scss"

type Props = PortableTextProps & {
  className?: string
}

const components: PortableTextProps["components"] = {
  block: {
    h1: ({ children }) => <Heading variant={1}>{children}</Heading>,
    h2: ({ children }) => <Heading variant={2}>{children}</Heading>,
    h3: ({ children }) => <Heading variant={3}>{children}</Heading>,
    h4: ({ children }) => <Heading variant={4}>{children}</Heading>,
    h5: ({ children }) => <Heading variant={5}>{children}</Heading>,
    h6: ({ children }) => <Heading variant={6}>{children}</Heading>,
    small: ({ children }) => (
      <Typography variant="small" component="small">
        {children}
      </Typography>
    ),
  },
}

function PortableText({ value, className }: Props) {
  return (
    <div className={clsx(s.portableText, className)}>
      <BasePortableText value={value} components={components} />
    </div>
  )
}

export default PortableText
export type { Props as PortableTextProps }
