import PortableText from "components/PortableText"
import Logo from "components/shared/Logo"
import { Button } from "components/shared/panda/Button"
import { useSettings } from "context/SettingsContext"
import { getHref } from "lib/helpers"
import Link from "next/link"
import { useRouter } from "next/router"
import {
  Container,
  Divider,
  Grid,
  HStack,
  Stack,
  styled,
  VStack,
  Wrap,
} from "styled-system/jsx"

type Props = { simple?: boolean }

function Footer({ simple }: Props) {
  const { locale } = useRouter()
  const {
    footerLogo,
    footerColumns,
    footerTopLinks,
    footerBottomLinks,
    footerBottomText,
    footerDescription,
    footerCta,
    footerFacebook,
    footerTwitter,
    footerLinkedin,
    footerInstagram,
  } = useSettings()

  return (
    <styled.footer
      css={{
        color: "white",
        // bgColor: "other.2",
        bg: "linear-gradient(180deg, #2C2089 -22.65%, #000A3A 67.42%)",
        pt: "72px",
        pb: 10,

        lg: {
          pt: "112px",
        },
      }}>
      <Container px={6}>
        <Grid
          columns={{ xl: 2 }}
          gap={"90px"}
          // direction={{ base: "column", md: "row" }}
        >
          <VStack
            alignItems="start"
            gap={{ base: "88px", md: "64px" }}
            order={{ xl: 1 }}>
            <Stack
              width="100%"
              justify="space-between"
              alignSelf={{ md: "end" }}
              direction={{ base: "column", md: "row" }}
              gap={"56px"}
              align={{ md: "center" }}>
              <styled.div
                title="Logo"
                css={{
                  width: "209px",
                  height: "42px",
                  hideFrom: "xl",
                }}>
                <Logo />
              </styled.div>
              {!simple && (
                <Stack
                  ml={{ md: "auto" }}
                  fontWeight="bold"
                  direction={{ base: "column", md: "row" }}
                  columnGap={6}
                  rowGap={3}>
                  {footerTopLinks?.map(({ text, url }, i) => (
                    <styled.a as={Link} href={getHref(url)} key={i + text}>
                      {text}
                    </styled.a>
                  ))}
                </Stack>
              )}
            </Stack>
            {!simple && (
              <Wrap direction={{ base: "column", md: "row" }} gap={"44px"}>
                {footerColumns?.map(({ title, content }) => (
                  <VStack
                    css={{
                      "& span": {
                        fontWeight: "bold",
                      },
                      "& a": {
                        opacity: 0.9,
                      },
                    }}
                    key={title}
                    alignItems="start"
                    gap={5}
                    width="150px">
                    <styled.span>{title}</styled.span>
                    {content?.map(x => {
                      if (x._type === "customCta")
                        return (
                          <styled.a
                            as={Link}
                            key={x._key}
                            href={getHref(x.url)}>
                            {x.text}
                          </styled.a>
                        )
                      else
                        return <PortableText key={x._key} value={x.content} />
                    })}
                  </VStack>
                ))}
              </Wrap>
            )}
          </VStack>

          <Grid columns={{ lgOnly: 2 }} columnGap={10}>
            <HStack gap={20} mb={"60px"}>
              <styled.div
                title="Logo"
                css={{
                  width: "209px",
                  height: "42px",
                  hideBelow: "xl",
                }}>
                <Logo />
              </styled.div>
              <HStack gap={4}>
                {footerFacebook && (
                  <styled.a
                    href={footerFacebook}
                    title="Facebook"
                    target="_blank">
                    <styled.img src="/facebook.svg" />
                  </styled.a>
                )}
                {footerTwitter && (
                  <styled.a
                    href={footerFacebook}
                    title="Twitter"
                    target="_blank">
                    <styled.img src="/twitter.svg" />
                  </styled.a>
                )}
                {footerLinkedin && (
                  <styled.a
                    href={footerLinkedin}
                    title="Linkedin"
                    target="_blank">
                    <styled.img src="/linkedin.svg" />
                  </styled.a>
                )}
                {footerInstagram && (
                  <styled.a
                    href={footerInstagram}
                    title="Instagram"
                    target="_blank">
                    <styled.img src="/instagram.svg" />
                  </styled.a>
                )}
              </HStack>
            </HStack>
            {!simple && (
              <>
                <styled.div mb={"40px"}>
                  <styled.p
                    fontSize={{ mdDown: "12px" }}
                    lineHeight={"24px"}
                    opacity={0.9}>
                    {footerDescription}
                  </styled.p>
                </styled.div>
                <styled.div mb={"80px"}>
                  {footerCta && (
                    <Button
                      as={Link}
                      fillColor="secondary"
                      size="medium"
                      href={getHref(footerCta)}>
                      {footerCta.text}
                    </Button>
                  )}
                </styled.div>
                <VStack gap={6} alignItems="start">
                  <HStack gap={4} flexWrap="wrap">
                    <styled.img
                      src="/reviews/software-advice.png"
                      css={{ height: "56px" }}
                    />
                    <styled.img
                      src="/reviews/getapp.png"
                      css={{ height: "56px" }}
                    />
                    <styled.img
                      src="/reviews/capterra.png"
                      css={{ height: "56px" }}
                    />
                  </HStack>
                  <HStack gap={4} flexWrap="wrap">
                    <styled.img
                      src="/reviews/ada.png"
                      css={{ height: "140px" }}
                    />
                    <styled.img
                      src="/reviews/gdpr.png"
                      css={{ height: "90px" }}
                    />
                    <styled.img
                      src="/reviews/enisa.jpeg"
                      css={{ height: "90px" }}
                    />
                    {/* <styled.div
                    css={{
                      bg: "white",
                      width: "140px",
                      height: "140px",
                      rounded: "50%",
                    }}
                  /> */}
                  </HStack>
                </VStack>
              </>
            )}
          </Grid>
        </Grid>

        <Divider color="white" opacity={0.8} mt={20} />
        <Stack
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          gap={{ base: "72px", md: 6 }}
          css={{
            py: { base: 10, md: 5 },
          }}>
          <styled.div opacity={0.8}>
            <span>{footerBottomText}</span>
          </styled.div>

          <Stack
            direction={{ base: "column", md: "row" }}
            gap={6}
            opacity={0.9}>
            {footerBottomLinks?.map(({ text, url }, i) => (
              <styled.a as={Link} href={getHref(url)} key={i + text}>
                {text}
              </styled.a>
            ))}
          </Stack>
        </Stack>
      </Container>
    </styled.footer>
  )
}

export default Footer
export type { Props as FooterProps }
