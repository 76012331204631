import Script from "next/script"

const GTAG_ID = process.env.NEXT_PUBLIC_GTAG_ID

function GTM() {
  if (!GTAG_ID) return null

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtm.js?id=${GTAG_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            'gtm.start': new Date().getTime(),
            event:'gtm.js'
          })
        `}
      </Script>
    </>
  )
}

export default GTM
