import Link from "next/link"
import { ComponentPropsWithoutRef } from "react"
import { cva, RecipeVariantProps } from "styled-system/css"
import { styled } from "styled-system/jsx"
import { SystemStyleObject } from "styled-system/types"

const buttonStyle = cva({
  base: {
    textWrap: "nowrap",
    position: "relative",
    // height: "fit-content",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 4,
    flexShrink: 0,
    rounded: "16px",
    backgroundRepeat: "repeat-x",
    backgroundSize: "200% 100%",
    cursor: "pointer",
    transition: "background 0.3s, box-shadow 0.3s",
  },
  variants: {
    fillColor: {
      primary: {
        bgGradient: "button.1",
      },
      secondary: {
        bgGradient: "button.2",
      },
      tertiary: {
        color: "primary.4",
        bg: "white",

        _hover: {
          boxShadow: "0px 4px 17px 0px rgba(140, 96, 250, 0.80)",
        },
      },
    },
    outlineColor: {
      primary: {
        _before: {
          background: "token(gradients.button.3)",
        },

        _hover: {
          boxShadow: "0px 4px 17px 0px rgba(78, 202, 224, 0.40)",
        },
      },
      secondary: {
        _before: {
          background: "token(gradients.button.4)",
        },

        _hover: {
          boxShadow: "0px 4px 17px 0px rgba(180, 150, 255, 0.40)",
        },
      },
    },
    variant: {
      filled: {},
      outline: {
        _before: {
          display: "block",
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          rounded: "16px",
          border: "2px solid transparent",
          WebkitMask:
            "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
          WebkitMaskComposite: "destination-out",
          maskComposite: "exclude",
        },
      },
    },
    size: {
      large: {
        minHeight: "62px",
        textStyle: "button.large",
        padding: "16px 40px",
      },
      medium: {
        textStyle: "button.medium",
        padding: "16px 24px",
      },
    },
    hoverStyle: {
      1: {
        _hover: {
          backgroundPosition: "100% 0",
        },
      },
      2: {
        _hover: {
          backgroundPosition: "-100% 0",
        },
      },
    },
    responsive: {
      true: {
        lgDown: {
          fontSize: "10px",
          padding: "8px 12px",
          rounded: "6px",
          _before: {
            rounded: "6px",
          },
        },
      },
    },
    responsiveAlt: {
      true: {
        lgDown: {
          fontSize: "12px",
          padding: "10px 24px",
          rounded: "10px",
          _before: {
            rounded: "10px",
          },
        },
      },
    },
  },
  defaultVariants: {
    variant: "filled",
    // outlineColor: "primary",
    // fillColor: "primary",
    size: "large",
    hoverStyle: 1,
  },
})

export type ButtonVariants = RecipeVariantProps<typeof buttonStyle>
export const StyledButton = styled("button", buttonStyle)

export type ButtonProps = {
  children
  href?: string
  css?: SystemStyleObject
} & ButtonVariants &
  ComponentPropsWithoutRef<"button">

export const Button = ({ children, href, ...props }: ButtonProps) => {
  return (
    <StyledButton
      // @ts-ignore
      as={href ? Link : "button"}
      href={href}
      {...props}>
      {children}
    </StyledButton>
  )
}
