import { mapObject, __spreadValues } from '../helpers.mjs';
import { css } from '../css/index.mjs';

const containerConfig = {
transform(props) {
  return __spreadValues({
    position: "relative",
    maxWidth: "7xl",
    mx: "auto",
    px: { base: "4", md: "6", lg: "8" }
  }, props);
}}

export const getContainerStyle = (styles = {}) => containerConfig.transform(styles, { map: mapObject })

export const container = (styles) => css(getContainerStyle(styles))
container.raw = getContainerStyle