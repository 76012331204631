import MenuDropdown from "components/layout/MainHeader/MenuDropdown"
import { getHref } from "lib/helpers"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useRef, useState } from "react"
import { Settings } from "schemas"
import { styled } from "styled-system/jsx"
import { useOnClickOutside } from "usehooks-ts"

import s from "./MenuSection.module.scss"

type Props = Settings["headerLinks"][0]

function MenuSection({ dropdown, title, url, hasDropdown, ...rest }: Props) {
  const { locale, asPath } = useRouter()
  const isActive = asPath.slice(1) === encodeURIComponent(getHref(url).slice(1))

  const [open, setOpen] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>()
  const ref = useRef<HTMLDivElement>()
  useOnClickOutside(ref, e => {
    // if (!ref.current.contains(e.target)) setOpen(false)
    if (wrapperRef.current.contains(e.target)) return
    setOpen(false)
  })

  useEffect(() => {
    setOpen(false)
  }, [asPath])

  if (hasDropdown)
    return (
      <div ref={wrapperRef} className={s.wrapper}>
        <div className={s.dropdownWrapper}>
          <span onClick={() => setOpen(true)} style={{ fontWeight: "bold" }}>
            {title}
          </span>
          <styled.svg
            onClick={() => setOpen(true)}
            css={{
              stroke: "currentcolor",
              ml: "3px",
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="15px"
            height="15px"
            viewBox="0 0 15 15"
            fill="none">
            <styled.path
              d="M11.1322 4.87216C10.9621 4.87216 10.8075 4.934 10.6838 5.05769L7.26693 8.61373L3.85004 5.05769C3.61812 4.81032 3.21614 4.81032 2.96876 5.04223C2.72139 5.27415 2.72139 5.67613 2.9533 5.92351L6.81856 9.94337C6.94224 10.0671 7.09685 10.1289 7.26693 10.1289C7.437 10.1289 7.59161 10.0671 7.71529 9.94337L11.5806 5.92351C11.8125 5.67613 11.8125 5.28961 11.5651 5.04223C11.4414 4.934 11.2868 4.87216 11.1322 4.87216Z"
              fill="#DEDEDE"
            />
          </styled.svg>
          {/* <Image
            alt=""
            width={16}
            height={16}
            src={chev}
            style={{
              filter:
                "invert(66%) sepia(8%) saturate(358%) hue-rotate(193deg) brightness(82%) contrast(83%)",
            }}
          /> */}
          {open && <MenuDropdown forwardRef={ref} dropdown={dropdown} />}
        </div>
      </div>
    )

  return (
    <div className={s.wrapper}>
      <styled.a
        as={Link}
        href={getHref(url)}
        locale={locale}
        aria-current={isActive ? "page" : null}
        css={{
          fontWeight: "bold",
          // transition: "colors",

          _hover: {
            color: "primary.3",
          },

          _currentPage: {
            color: "#9482FC!",
          },
        }}>
        {title}
      </styled.a>
    </div>
  )
}

export default MenuSection
export type { Props as MenuSectionProps }
